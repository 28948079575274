// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/buttons/basic-button.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/buttons/basic-button.tsx");
  import.meta.hot.lastModified = "1726706773000";
}
// REMIX HMR END

import { ClipLoader } from "react-spinners";
export function PLBasicButton({
  rounded = false,
  icon = "",
  text,
  onClick,
  colorClasses,
  disabled = false,
  useStaticWidth,
  noDefaultDarkModeStyles,
  iconSide = 'left',
  iconColorClass = '',
  showLoader
}) {
  const defaultColorClasses = "bg-gray-300 text-gray-800 group" + (noDefaultDarkModeStyles ? ' ' : ' dark:bg-neutral-800 dark:text-neutral-400 ') + (disabled ? ' cursor-not-allowed opacity-50' : ' cursor-pointer hover:bg-gray-400 dark:hover:bg-neutral-900');
  return <button className={defaultColorClasses + " font-bold py-2 px-4 inline-flex items-center " + (rounded ? ' rounded-xl ' : ' rounded ') + (colorClasses ? ' ' + colorClasses : '') + (useStaticWidth ? ' w-40 text-center' : '')} onClick={onClick} disabled={disabled}>
      {!showLoader && icon.length && iconSide === 'left' ? <i className={icon + " mr-2 " + iconColorClass}></i> : null}
      {showLoader && <ClipLoader loading={true} size={15} color="rgb(234 88 12)" />}
      <span className={(useStaticWidth ? 'mx-auto' : '') + (showLoader ? ' ml-2' : '')}>{text}</span>
      {!showLoader && icon.length && iconSide === 'right' ? <i className={icon + " ml-2 " + iconColorClass}></i> : null}
    </button>;
}
_c = PLBasicButton;
var _c;
$RefreshReg$(_c, "PLBasicButton");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;